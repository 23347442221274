import {useEffect, useState} from 'react';
import axios from 'utils/axios'

export default function WeatherSummary() {
    const [weather, setWeather] = useState<Weather>()
    useEffect(refreshWeather, [])

    function refreshWeather(): void {
        axios.get<Weather>('/v1/weather/latest').then(({data}) => setWeather(data))
    }

    return (
        <div>
            <b>Pogoda</b>
            <hr />
            <WeatherControls refreshHandler={refreshWeather} />
            <hr />
            <WeatherData data={weather} />
        </div>
     )
}

function WeatherControls({refreshHandler}: any) {
    const [autoRefresh, setAutoRefresh] = useState(true)
    useEffect(doAutoRefresh, [autoRefresh, refreshHandler])

    function doAutoRefresh(): any {
        if (autoRefresh) {
            const REFRESH_INTERVAL = process.env.REACT_APP_WEATHER_REFRESH_INTERVAL * 1000
            const interval = setInterval(refreshHandler, REFRESH_INTERVAL)
            return () => clearInterval(interval)
        }
    }

    function switchAutoRefresh(event: any): void {
        setAutoRefresh(event.target.checked)
    }

    return (
        <div>
            Auto <input type='checkbox' defaultChecked={autoRefresh} onClick={switchAutoRefresh} />
            <button onClick={refreshHandler}>Odśwież</button>
        </div>
    )
}

function WeatherData({data}: any) {
    return (
        <table>
            <tbody>
                <tr>
                    <td>Temperatura</td>
                    <td>: {data?.temperature} {data?.temperatureUnit}</td>
                </tr>
                <tr>
                    <td>Wiatr</td>
                    <td>: {data?.wind} {data?.windUnit}</td>
                </tr>
                <tr>
                    <td>Kierunek wiatru</td>
                    <td>: {data?.windDirection}</td>
                </tr>
                <tr>
                    <td>Zachmurzenie</td>
                    <td>: {data?.cloudsCoverage}</td>
                </tr>
            </tbody>
        </table>
    )
}