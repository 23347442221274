import axios from 'axios';

const baseUrl = window.location.href
    .toLowerCase()
    .replace("//www", "//api")

const config = {
    baseURL: baseUrl,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
}

export default axios.create(config)